<template>
    <div class="__main_card_container">
        <div class="_main_card_header">
            <div class="__header_inner admin_header_padding">
                <h2>
                    <button @click="dynamicBack"><img class="mr-2" src="../../../assets/Arrow - Right.svg" alt="img">
                        Back</button> <span>{{ $route.meta.header }}</span>
                </h2>
                <div class="side_nav_wrapper">
                    <div class="__side_nav">
                        <button class="btn"
                            :class="[$route.path == '/admin/finance/bundle_history' ? '__side_item_active' : '']"
                            @click="$router.replace('/admin/finance/bundle_history').catch((err) => {
                                if (err.name !== 'NavigationDuplicated') throw err;
                            })">
                            Bundle History
                        </button>
                        <button class="btn"
                            :class="[$route.path == '/admin/finance/wallet_history' ? '__side_item_active' : '']"
                            @click="$router.replace('/admin/finance/wallet_history').catch((err) => {
                                if (err.name !== 'NavigationDuplicated') throw err;
                            })">
                            Wallet History
                        </button>
                        <button class="btn"
                            :class="[$route.path == '/admin/finance/debtors_list' ? '__side_item_active' : '']"
                            @click="$router.replace('/admin/finance/debtors_list').catch(err => { })">
                            Debtors List
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="main_card_body">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import appConfig from '@/app.config'
export default {
    page: {
        meta: [{ name: 'description', content: appConfig.description }],
    },
    components: {

    },
    data() {
        return {

        }
    },
    methods: {
        dynamicBack() {
            this.$router.go(-1)
        }
    },
}
</script>

<style scoped>
button {
    outline: none;
    border: none;
    background-color: transparent;
    font-family: 'Graphik Regular';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    text-transform: capitalize;
    color: #F7921C;
    margin-right: 1em;
}
</style>